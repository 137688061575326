@import './vars.scss';
@import '@angular/cdk/overlay-prebuilt.css';
@import "@fortawesome/fontawesome-free/css/all.css";
@import '../node_modules/ngx-sharebuttons/themes/default.scss';
//@import "~ngx-sharebuttons/themes/default";
 
@font-face {
    font-family: "Nexa Black";
    src: url("assets/fonts/Nexa-Black.woff2") format("woff2"),
}
@font-face {
    font-family: "Nexa Bold";
    src: url("assets/fonts/Nexa Bold.otf") format("opentype"),
}
@font-face {
    font-family: "Nexa Light";
    src: url("assets/fonts/Nexa Light.otf") format("opentype"),
}
@font-face {
    font-family: "Nexa Regular";
    src: url("assets/fonts/Nexa-Regular.woff2") format("woff2"),
}
@font-face {
    font-family: "Leisha";
    src: url("assets/fonts/Leisha.otf") format("opentype"),
}

html, body { height: 100%; box-sizing: border-box !important; }
body { margin: 0; font-family: 'Nexa Regular', sans-serif; }
h2 { font-family: 'Nexa Black', sans-serif; font-weight: lighter; font-size: 3.5vw; }
h3 { font-family: 'Nexa Black', sans-serif; font-weight: lighter; font-size: 2.5vw; margin-bottom: 1.7vw; line-height: 2.8vw; }
h4 { font-family: 'Nexa Black', sans-serif; font-weight: lighter; font-size: 1.5vw; }
h5 { font-family: 'Nexa Black', sans-serif; font-weight: lighter; font-size: 1.1vw; }
h6 { font-family: 'Nexa Black', sans-serif; font-weight: lighter; font-size: 0.9vw; }
section { padding: 4rem 0.5rem; display: flex; justify-content: center;}
.content { position: relative; width: 100%; }
button { border: none; border-radius: 2rem; color: #fff; font-size: 0.8rem; padding: 0.7rem 1.3rem; font-family: 'Nexa Black', sans-serif; letter-spacing: 1px; cursor: pointer; }
button.pink { background: $pink; }
button.light-blue { background: $light_blue; }
button.secondary { background: #ccc; color: $dark_gray; }
button.medium { font-size: 1.25rem; padding: 0.9rem 2.1rem; border-radius: 2rem; }
button:disabled { cursor: not-allowed; opacity: 0.5; }

label.required-field:after { content: " *"; color: red; }

curve-top { margin-bottom: -2px;}
curve-bottom { margin-top: -2px;}

button.round { margin-right: -1.5rem; font-size: 1.5rem; padding: 0; width: 2.5rem; height: 2.5rem; color: rgba(0, 0, 0, 0.6); border: 0 none; background: transparent; border-radius: 50%; transition: background-color 0.2s, color 0.2s, box-shadow 0.2s; }
button.round:hover { background: rgba(0, 0, 0, 0.04); }

.flex-row { display: flex; align-items: center; }
.flex-row > * { margin-right: 3rem; }
.flex-row.tight > * { margin-right: 1rem; }
.flex-row.tighter > * { margin-right: 0.3rem; }
.flex-row > *:last-child { margin-right: 0; }

.flex-column { display: flex; flex-direction: column; align-items: flex-start; }
.flex-column > * { margin-bottom: 3rem; }
.flex-column.tight > * { margin-bottom: 1rem; }
.flex-column.tighter > * { margin-bottom: 0.3rem; }
.flex-column > *:last-child { margin-bottom: 0; }
.flex-column.center { align-items: center; }
.flex-1 { flex: 1; }

.text-with-icon { display: flex; align-items: center; }
.text-with-icon > i, .text-with-icon > img { margin-right: 0.5rem; }
.text-with-icon > img { width: auto; height: 1.5rem; margin-right: 0.5rem; }

button.with-icon { display: flex; align-items: center; }
button.with-icon > i { margin-right: 1rem; }
button.with-icon.right > i { margin-left: 1rem; margin-right: 0; }

input, select { font-family: 'Nexa Regular', sans-serif; box-sizing: border-box; }

input[type=text], 
input[type=tel], 
input[type=email], 
input[type=number],
select,
input[type=date] { 
    font-size: 1rem; color: rgba(0, 0, 0, 0.87); background: #ffffff; padding: 0.5rem 0.5rem; border: 1px solid rgba(0, 0, 0, 0.38);
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    appearance: none; border-radius: 4px; margin: 0;
}

select { 
    appearance: auto;
}

input[type=text]:enabled:hover, 
input[type=tel]:enabled:hover, 
input[type=email]:enabled:hover, 
input[type=number]:enabled:hover,
select:enabled:hover,
input[type=date]:enabled:hover { 
    border-color: rgba(0, 0, 0, 0.87);
}

input[type=text].ng-invalid.ng-touched, 
input[type=tel].ng-invalid.ng-touched, 
input[type=email].ng-invalid.ng-touched, 
input[type=number].ng-invalid.ng-touched,
select.ng-invalid.ng-touched,
input[type=date].ng-invalid.ng-touched { 
    border: 1px solid red;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.full-height-dialog { height: 100%; }

.error { color: red; display: block; }
span.secondary { color: $light_gray; }

.field { margin-bottom: 1rem; }
.field.full-width, .field.full-width input, .field.full-width select { width: 100%; }

.p2-app .card, .p2-dialog .card { background: #ffffff; color: rgba(0, 0, 0, 0.87); box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border-radius: 4px; padding: 1.5rem; box-sizing: border-box; }
.card h2, .card h4, .card > h5 { margin-top: 0; }

.p2-dialog { display: flex; flex-direction: column; background: #fff; border-radius: 8px; min-width: 15rem; max-width: 100%; }
.p2-dialog input, .p2-dialog select-button { margin: 8px 0; }

.p2-dialog header { display: flex; align-items: center; padding: 1rem 2rem; }
.p2-dialog header > h1 { font-size: 1.2rem; flex: 1; white-space: nowrap; margin: 0; }
.p2-dialog header > button.close { margin-right: -1.5rem; font-size: 1.5rem; padding: 0; width: 2.5rem; height: 2.5rem; color: rgba(0, 0, 0, 0.6); border: 0 none; background: transparent; border-radius: 50%; transition: background-color 0.2s, color 0.2s, box-shadow 0.2s; }
.p2-dialog header > button.close:hover { background: rgba(0, 0, 0, 0.04); }

.p2-dialog .content { padding: 1rem 2rem; width: auto; flex: 1; overflow: auto; }

.p2-dialog footer { display: flex; align-items: center; justify-content: flex-end; padding: 1rem 2rem 1rem 2rem; }
.p2-dialog footer > button { margin-right: 0.5rem; }
.p2-dialog footer > button:last-child { margin-right: 0; }

.loading-indicator { display: inline-block; position: relative; width: 30px; height: 30px; }

.lds-dual-ring, .lds-dual-ring:after { box-sizing: border-box; }
.lds-dual-ring { display: inline-block; position: relative; }
.lds-dual-ring:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 35%;
  display: flex;
  flex-direction: column;
  width: 32px;
  height: 90%;
  border-radius: 50%;
  border: 6.4px solid currentColor;
  border-color: currentColor transparent currentColor transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.p2-app section { padding: 0 10vw 4vw 10vw; background: #F1F7FA; }
.p2-app h2 { font-size: 2.5rem; line-height: 2.7rem; margin: 3rem 0;}
.p2-app h4 { font-size: 1.5rem; line-height: 1.9rem; margin: 2rem 0;}
.p2-app h5 { font-size: 1.2rem; line-height: 1.5rem; margin: 2rem 0;}
.p2-app form { display: block; width: 100%; }

.p2-dialog h5 { font-size: 1.3rem; line-height: 1.3rem; margin: 2rem 0;}
.p2-dialog a, .p2-app a { color: $light_blue; text-decoration: underline; cursor: pointer; }
.p2-dialog a:hover, .p2-app a:hover { color: lighten($light_blue, 10%); }

i.success { color: green; }

@media screen and (max-width: 769px) {
    .p2-app section { padding: 0 6vw 4vw 6vw; }
    .p2-app h2 { font-size: 8vw; line-height: 8vw; }
    .p2-app h4 { font-size: 6vw; line-height: 6vw; }
    .p2-app h5 { font-size: 5vw; line-height: 5vw; }
    
    .mobile-only { display: block !important; }
    .full-only { display: none !important; }

    .flex-row.responsive { flex-direction: column !important; align-items: flex-start; flex: 1; margin-bottom: 1rem; width: 100%; }
    .flex-row.responsive > * { margin-right: 0; margin-bottom: 1rem; width: 100%; }
    .flex-row.responsive.tight > * { margin-bottom: 1rem;}
    .flex-row.responsive.tighter > * { margin-bottom: 0.5rem;}
    .flex-row.responsive > *:last-child { margin-bottom: 0; }
    .flex-row.responsive .field input { display: block; width: 100%; }
}